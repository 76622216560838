import React from "react";
import loadable from "@loadable/component";

import { CbImages, CbCopy } from "@molecules";

const TypeformEmbed = loadable(() => import("@organisms/TypeformEmbed"));

const ContainedSmallImageGrid = loadable(() =>
  import("@organisms/ContainedSmallImageGrid")
);
const SmallImageGrid = loadable(() => import("@organisms/SmallImageGrid"));
const CaseStudyCarousel = loadable(() =>
  import("@organisms/CaseStudyCarousel")
);
const RecentPublications = loadable(() =>
  import("@organisms/RecentPublications")
);
const SectionHeading = loadable(() => import("@organisms/SectionHeading"));
const NewsletterDonate = loadable(() => import("@organisms/NewsletterDonate"));
const Supporters = loadable(() => import("@organisms/Supporters"));
const LatestEntries = loadable(() => import("@organisms/LatestEntries"));
const ProgramsOverview = loadable(() => import("@organisms/ProgramsOverview"));
const EndofPageCta = loadable(() => import("@organisms/EndofPageCta"));
const PeopleGrid = loadable(() => import("@organisms/PeopleGrid"));
const List = loadable(() => import("@organisms/List"));
const SimpleButtonList = loadable(() => import("@organisms/SimpleButtonList"));
const IconCopyGrid = loadable(() => import("@organisms/IconCopyGrid"));
const ContainedIconGrid = loadable(() =>
  import("@organisms/ContainedIconGrid")
);
const ImageCopy = loadable(() => import("@organisms/ImageCopy"));
const ImageLead = loadable(() => import("@organisms/ImageLeadCopy"));
const ButtonList = loadable(() => import("@organisms/ButtonList"));
const ContainedAccordion = loadable(() =>
  import("@organisms/ContainedAccordion")
);
const Accordion = loadable(() => import("@organisms/Accordion"));
const TestimonialBlock = loadable(() => import("@organisms/TestimonialBlock"));
const TestimonialGrid = loadable(() => import("@organisms/TestimonialGrid"));
const TestimonialCarousel = loadable(() =>
  import("@organisms/TestimonialCarousel")
);
const StatsOverview = loadable(() => import("@organisms/StatsOverview"));
const ImageGrid = loadable(() => import("@organisms/ImageGrid"));
const CaseStudyGrid = loadable(() => import("@organisms/CaseStudyGrid"));
const ThreeColumnContent = loadable(() =>
  import("@organisms/ThreeColumnContent")
);
const ImageAccordion = loadable(() => import("@organisms/ImageAccordion"));
const ContactForm = loadable(() => import("@organisms/ContactForm"));
const LearningTracks = loadable(() => import("@organisms/LearningTracks"));
const OfferingsTable = loadable(() => import("@organisms/OfferingsTable"));
const CtaColored = loadable(() => import("@organisms/CtaColored"));
const NumberedList = loadable(() => import("@organisms/NumberedList"));
const OverviewGrid = loadable(() => import("@organisms/OverviewGrid"));
const Featured = loadable(() => import("@organisms/Featured"));
const CardGrid = loadable(() => import("@organisms/CardGrid"));

const PageBuilder = ({ blocks, hasHero, home }) => {
  return (
    <>
      {blocks.map((b, i) => {
        const { type, uid } = b;
        switch (type) {
          case "typeformEmbed":
            return <TypeformEmbed {...b} key={uid} />;
          case "copy":
            return <CbCopy {...b} key={uid} />;
          case "images":
            return <CbImages {...b} key={uid} />;
          case "imageLeadCopy":
            return <ImageLead key={uid} {...b} />;
          case "imageCopy":
            return <ImageCopy key={uid} {...b} />;
          case "buttonList":
            return <ButtonList key={uid} {...b} />;
          case "sectionHeading":
            return (
              <SectionHeading idx={i} hasHero={hasHero} key={uid} {...b} />
            );
          case "newsletterDonateForms":
            return <NewsletterDonate key={uid} {...b} />;
          case "organizationCarousel":
            return <Supporters key={uid} {...b} />;
          case "latestEntries":
            return <LatestEntries key={uid} {...b} pull={i === 0 && home} />;
          case "cta":
            return <EndofPageCta key={uid} {...b} />;
          case "peopleGrid":
            return <PeopleGrid key={uid} {...b} />;
          case "list":
            return <List key={uid} {...b} />;
          case "simpleButtonList":
            return <SimpleButtonList key={uid} {...b} />;
          case "iconGrid":
            return <IconCopyGrid key={uid} {...b} />;
          case "containedIconGrid":
            return <ContainedIconGrid key={uid} {...b} />;
          case "containedAccordion":
            return <ContainedAccordion key={uid} {...b} />;
          case "jobOpenings":
            return <Accordion key={uid} {...b} showBorders />;
          case "copyAccordion":
            return <Accordion key={uid} {...b} showBorders />;
          case "singleTestimonial":
            return <TestimonialBlock key={uid} {...b} />;
          case "testimonialGrid":
            return <TestimonialGrid key={uid} {...b} />;
          case "statsGrid":
            return <StatsOverview key={uid} {...b} />;
          case "imageWCopyGrid":
            return <ImageGrid key={uid} {...b} />;
          case "recentCaseStudies":
            return <CaseStudyGrid key={uid} {...b} />;
          case "threeColumnContent":
            return <ThreeColumnContent key={uid} {...b} />;
          case "recentPublications":
            return <RecentPublications key={uid} {...b} />;
          case "caseStudyCarousel":
            return <CaseStudyCarousel key={uid} {...b} />;
          case "smallImageGrid":
            return <SmallImageGrid key={uid} {...b} />;
          case "containedSmallImageGrid":
            return <ContainedSmallImageGrid key={uid} {...b} />;
          case "imageAccordion":
            return <ImageAccordion key={uid} {...b} />;
          case "hubspotForm":
            return <ContactForm key={uid} {...b} />;
          case "programsOverview":
            return <ProgramsOverview key={uid} {...b} />;
          case "testimonialCarousel":
            return <TestimonialCarousel key={uid} {...b} />;
          case "utilityAnchorLink":
            return <div className="anchorUtility" key={uid} id={b.anchor} />;
          case "imageGridWLinks":
            return <ImageGrid key={uid} {...b} />;
          case "learningTracks":
            return <LearningTracks key={uid} {...b} />;
          case "offeringsTable":
            return <OfferingsTable key={uid} {...b} />;
          case "ctaColored":
            return <CtaColored key={uid} {...b} />;
          case "numberedList":
            return <NumberedList key={uid} {...b} />;
          case "overviewGrid":
            return <OverviewGrid key={uid} {...b} />;
          case "featured":
            return <Featured key={uid} {...b} />;
          case "cardGrid":
            return <CardGrid key={uid} {...b} />;
          default:
            return <div>{type}</div>;
        }
      })}
    </>
  );
};

PageBuilder.defaultProps = {
  hasHero: true,
};

export default PageBuilder;
